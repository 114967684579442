import TaskRunner from '../base/TaskRunner';
import LiveEvent from '../base/LiveEvent';

TaskRunner.add(() => {
    document.querySelectorAll('[data-filter-target]').forEach(flt => {
        const filterTarget = document.getElementById(flt.getAttribute('data-filter-target'));

        flt.addEventListener('filterChanged', (e) => {
            const fltVal = e.target.getAttribute('data-filter');

            try {
                if (filterTarget) {
                    if(!filterTarget.filterTargets){
                        filterTarget.filterTargets = filterTarget.querySelectorAll('.filter_buttons--target');
                        filterTarget.wasteParent = document.createElement('div');
                        filterTarget.wasteParent.classList.add('d-none');
                        filterTarget.parentElement.appendChild(filterTarget.wasteParent);
                        filterTarget.filterTargetsParent = filterTarget.filterTargets[0].parentElement;
                    }

                    filterTarget.filterTargets.forEach(targ => {
                        targ.classList.remove('swiper-slide-active','swiper-slide-next','swiper-slide-prev');

                        if (fltVal && fltVal.length > 0 && !targ.classList.contains(fltVal)) {
                            filterTarget.wasteParent.appendChild(targ);
                        } else {
                            filterTarget.filterTargetsParent.appendChild(targ);
                        }
                    });

                    if (filterTarget.classList.contains('swiper-root')) {
                        const swiperContainer = filterTarget.querySelector('.swiper-container');
                        let swiper = swiperContainer.swiper;

                        if (swiper) {
                            swiper.update();

                            if(swiper.lazy){
                                swiper.lazy.load();
                            }
                        }
                    }
                }
            } catch (err) {
                console.warn('Unable to init filter: ' + err.message);
            }
        });
    });

    document.querySelectorAll('.filter_buttons--button:first-child').forEach(btn => {
        btn.classList.add('active');

        if(btn.getAttribute('data-filter').length > 0){
            btn.dispatchEvent(new Event('filterChanged', { bubbles: true }));
        }
    });

    LiveEvent
        .on('click', '.filter_buttons--button', (e, targ) => {
            targ.dispatchEvent(new Event('filterChanged', { bubbles: true }));
            const currActiveButton = targ.parentElement.querySelector('.filter_buttons--button.active');

            if (currActiveButton && currActiveButton !== targ) {
                currActiveButton.classList.remove('active');
            }

            targ.classList.add('active');
        });
})