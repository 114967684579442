/**
 * Различные утилиты, геттеры и т.д.
 */

let scrollTop = 0;
let bodyFixable = false;
let bodyScrollEnabled = true;

let scrollDisableElem = document.documentElement;

export const isTouch = () => {
    var prefixes = ' -webkit- -moz- -o- -ms- '.split(' ');
    var mq = function (query) {
        return window.matchMedia(query).matches;
    };

    if (('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch) {
        return true;
    }

    // include the 'heartz' as a way to have a non matching MQ to help terminate the join
    // https://git.io/vznFH
    var query = ['(', prefixes.join('touch-enabled),('), 'heartz', ')'].join('');
    return mq(query);
};

export const isIe = () => {
    return navigator.userAgent.match(/Trident\/7.0/i);
};

export const isSafari = () => {
    return /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
};

export const refreshCustomVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
};

export const getScrollbarWidth = () => {
    let documentWidth = parseInt(document.documentElement.clientWidth),
        windowsWidth = parseInt(window.innerWidth);

    return windowsWidth - documentWidth;
};

export const ww = () => {
    return window.innerWidth || document.documentElement.clientWidth;
};

export const wh = () => {
    return window.innerHeight || document.documentElement.clientHeight;
};

export const isBodyScrollEnabled = () => {
    return bodyScrollEnabled;
};

export const isBodyFixable = () => {
    return bodyFixable;
};

export const bodyDisableScroll = () => {
    scrollTop = scrollDisableElem.scrollTop;
    scrollDisableElem.classList.add('scroll-disabled');

    if (bodyFixable) scrollDisableElem.style.top = (-scrollTop) + 'px';

    bodyScrollEnabled = false;
};

export const bodyEnableScroll = () => {
    scrollDisableElem.classList.remove('scroll-disabled');

    if (bodyFixable) {
        scrollDisableElem.style.top = '';
        scrollDisableElem.scrollTop = scrollTop;
    }

    bodyScrollEnabled = true;
};

export const rootElem = () => {
    return scrollDisableElem;
};

export const zeroPad = (num, places) => String(num).padStart(places, '0');

bodyFixable = isTouch() && isSafari();