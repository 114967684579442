import Popup from '../components/Popup.js';
import $wrap from '../base/jqWrap';
import TaskRunner from '../base/TaskRunner';

TaskRunner.add(() => {
    if (typeof $ !== 'undefined')
        $.fn.modal = function (...params) {
            return $wrap(this, Popup, 'popup', params);
        };
    
    window.Popup = Popup;

    Popup.initAll();
});