import TaskRunner from '../base/TaskRunner';
import LiveEvent from '../base/LiveEvent';
import 'fslightbox';

TaskRunner.add(() => {
    LiveEvent.on('click', '[data-opengal]', (e, targ) => {
        let srcSet = [];
        let galId = targ.getAttribute('data-opengal');

        document.querySelectorAll(`a[data-fslightbox="${galId}"]`).forEach(elem => {
            srcSet.push(elem.getAttribute('href'));
        });

        if (srcSet.length > 0) {
            let lb = new FsLightbox();
            lb.props.sources = srcSet;
            lb.open();
        }
        
        e.preventDefault();
    });
});