export default {
    heroSlider: {
        loop: false,
        slidesPerView: 1,
        navigation: 'default',
        pagination: 'fraction',
        effect: 'fade',
        speed: 0
    },
    /*  mainPageSlider: {
         loop: false,
         pagination: 'default',
         direction: 'vertical',
         slidesPerView: 1,
         speed: 700,
         preventInteractionOnTransition: true,
         keyboard: {
             enabled: true,
         },
         mousewheel: true
     }, */
    mainAbouSlider: {
        loop: false,
        navigation: 'default',
        effect: 'fade',
        speed: 0,
        slidesPerView: 1,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        preloadImages: false
    },
    mainGroupImageSlider: {
        effect: 'fade',
        speed: 0,
        lazy: true,
        preloadImages: false,
        allowTouchMove: false,
        linkWith: '#mainGroupListSlider'
    },
    mainGroupListSlider: {
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 10
        },
        preloadImages: true,
        linkWith: '#mainGroupImageSlider',
        allowTouchMove: false,
        speed: 0,
        slideToClickedSlide: true
    },
    advantagesSlider: {
        effect: 'fade',
        autoHeight: true,
        navigation: 'default',
        lazy: true,
        preloadImages: true,
        speed: 0,
        slideToClickedSlide: true,
        nested: true,
        breakpoints: {
            922: {
                allowTouchMove: false,
                followFinger: false,
                shortSwipes: false,
                longSwipes: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 4
                }
            }
        }
    },
    mainNewsSlider: {
        effect: 'fade',
        speed: 0,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        preloadImages: true,
        slidesPerView: 'auto',
        watchOverflow: true,
        autoHeight: true,
        navigation: 'default',
        breakpoints: {
            1440: {
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 2
                }
            }
        }
    },
    historySlider: {
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        preloadImages: false,
        watchOverflow: true,
        slidesPerView: 'auto',
        navigation: 'default',
        pagination: 'customProgress',
        spaceBetween: 40,
        autoHeight: true,
        breakpoints: {
            768: {
                autoHeight: false,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3
                }
            },
            922: {
                autoHeight: false,
                spaceBetween: 70,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 4
                }
            }
        }
    },
    personsSlider: {
        slidesPerView: 'auto',
        watchOverflow: true,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 2
        },
        spaceBetween: 30,
        navigation: 'default',
        breakpoints: {
            768: {
                spaceBetween: 40,
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 3
                }
            },
            1200: {
                lazy: {
                    loadPrevNext: true,
                    loadPrevNextAmount: 4
                }
            }
        }
    },
    partnersSliderText: {
        slidesPerView: 1,
        watchOverflow: true,
        effect: 'fade',
        autoHeight: true,
        thumbs: {
            swiper: {
                el: '.page_holding--block_partners-slider_nav',
                freeMode: true,
                slidesPerView: 'auto',
                watchOverflow: true
            }
        }
    },
    customersSlider: {
        slidesPerView: 'auto',
        watchOverflow: true,
        navigation: 'default',
        breakpoints: {
            1200: {
                slidesPerColumn: 2,
                slidesPerColumnFill: 'row'
            }
        }
    },
    singleImageSlider: {
        slidesPerView: 1,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        watchOverflow: true,
        navigation: 'default',
        preloadImages: false
    },
    facilitySlider: {
        slidesPerView: 1,
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        watchOverflow: true,
        navigation: 'default',
        preloadImages: false,
        autoHeight: true
    },
    featuredSlider: {
        lazy: {
            loadPrevNext: true,
            loadPrevNextAmount: 1
        },
        watchOverflow: true,
        slidesPerView: 1,
        navigation: 'default',
        autoHeight: true,
        breakpoints: {
            768: {
                slidesPerView: 2,
                spaceBetween: '40px'
            }
        }
    }
};