import * as util from '../base/util';
import TaskRunner from '../base/TaskRunner';
import LiveEvent from '../base/LiveEvent';

import FloatMenu from '../components/FloatMenu';

TaskRunner.add(() => {
    let header = new FloatMenu(document.getElementById('pageHeader'));

    LiveEvent.on('click', '#menuBurger', (e) => {
        var has = header.toggleExpand();

        if (has) {
            util.bodyDisableScroll();
        } else {
            util.bodyEnableScroll();

            if(util.isBodyFixable()) header.show();
        }

        e.preventDefault();
    }).on('click', '#headerSearchToggle', (e, targ) => {
        targ.classList.toggle('toggle');
    });

    const langToggle = document.getElementById('headerLangToggle');
    const langToggleHandler = (e) => {
        e.target.classList.toggle('toggle');
    };
    const langToggleDocumentHandler = (e) => {
        if(e.target !== langToggle){
            langToggle.classList.remove('toggle');
        }
    };

    document.addEventListener('click', langToggleDocumentHandler);
    langToggle.addEventListener('click', langToggleHandler);
});