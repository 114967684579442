const FORMATTERS = {
    currencyDefault: (val) => {
        let filteredVal = val.replace(/[^0-9,.]+/g, '').replace(',', '.');
        const vals = filteredVal.match(/^(\d*)\.(.*)$/);

        if (vals != null && vals.length === 3) {
            filteredVal = `${vals[1]}.${vals[2].replace(/\.*/g, '')}`;
        }

        return Number(filteredVal).toLocaleString('ru-RU',{maximumFractionDigits: 2});
    }
};

export default FORMATTERS;