import TaskRunner from '../base/TaskRunner';
import LiveEvent from '../base/LiveEvent';

const onReset = (e) => {
    e.target.querySelectorAll('input.focused, textarea.focused, select.focused').forEach(field => {
        field.classList.remove('focused');
        field.setAttribute('value', '');
    });
};

const onBlur = (e) => {
    if (e.target.value.length === 0) {
        e.target.classList.remove('focused');
    }
};

const onFocus = (e) => {
    e.target.classList.add('focused');
};

TaskRunner.add(() => {
    let inputs = document.querySelectorAll('input, textarea, select');

    inputs.forEach(inputField => {
        if (inputField.value.length > 0) {
            inputField.classList.add('focused');
        }
    });

    LiveEvent
        .on('reset', 'form', onReset)
        .on('focusout', 'input, textarea, select', onBlur)
        .on('focusin', 'input, textarea, select', onFocus);
});