export default ($this, ClassName, varName, params) => {
    $this.each((i, v) => {
        let inst;

        if (
            params == undefined ||
            params.length === 0 ||
            typeof params[0] === 'object' ||
            typeof v[varName] === 'undefined'
        ) {
            inst = new ClassName(v, params != undefined ? params[0] : {});
        } else if (v[varName] != undefined) {
            inst = v[varName];
        }/*else{
            return $this;
        }*/

        if (
            inst != undefined &&
            params != undefined &&
            typeof params[0] == 'string' &&
            inst[params[0]] != undefined &&
            typeof inst[params[0]] == "function"
        ) {
            inst[params[0]].apply(inst, params.slice(1));
        }
    });

    return $this;
};