const animItems = [
    // Главная
    {
        selector: '.page_main--layout-slide'
    },
    {
        selector: `.page_inner--block-head > h1,
        .page_inner--block-head > h2,
        .page_inner--block-head > .block_text > *`,
        once: true,
        customOptions: {
            threshold: 1
        }
    },
    {
        selector: '.page_inner--block-content > *',
        once: true
    }
];

export default animItems;