/**
 * Таск раннер
 */
import { isTouch } from './util';

let tasks = [];
let isInit = false;

export default class TaskRunner {
    static run() {
        if (isInit) {
            console.warn('Tasks already run!');
            return;
        }

        tasks.forEach(task => {
            setTimeout(() => {
                try{
                    task.call();
                }catch(e){
                    TaskRunner.logError(e);
                }
            }, 0);
        });

        isInit = true;
        tasks = null;
    }

    static logError(error) {
        let message = `Error: ${error.name}\nMessage: ${error.message}`;

        if (isTouch()) {
            var errmess = document.createElement('span');
            errmess.classList.add('debug-message');
            errmess.innerText = message;
            document.body.appendChild(errmess);
        }

        console.error(message);
    }

    static add(taskFunc) {
        if (typeof taskFunc !== 'function') {
            console.warn('Wrong task type!');
            return;
        } else if (tasks.includes(taskFunc)) {
            console.warn('That task is already exist!');
            return;
        } else if (isInit) {
            taskFunc.call();
            return;
        }

        tasks.push(taskFunc);
    }
}