import * as util from '../base/util';
import TaskRunner from '../base/TaskRunner';
// import LiveEvent from '../base/LiveEvent';
import * as Watcher from '../components/viewportWatcher';

TaskRunner.add(() => {
    // Кастомный vh
    util.refreshCustomVH();

    window.addEventListener('resize', () => {
        util.refreshCustomVH();
    });

    document.documentElement.style.setProperty('--scrollbarWidth', util.getScrollbarWidth() + 'px');

    // LiveEvent
    //     // .on('click', '#pageScrollUp', (e) => {
    //     //     window.scrollTo({
    //     //         top: 0,
    //     //         behavior: 'smooth'
    //     //     });
    //     // })
    //     .on('click', '.countpicker-dec', (e, targ) => {
    //         const fld = targ.nextElementSibling;

    //         if(--fld.value === 1){
    //             targ.disabled = true;
    //         }
    //     })
    //     .on('click', '.countpicker-inc', (e, targ) => {
    //         const fld = targ.previousElementSibling;

    //         if(++fld.value === 2){
    //             fld.previousElementSibling.disabled = false;
    //         }
    //     })
    //     .on('change', '.countpicker-num', (e, targ) => {
    //         if(targ.value <= 1){
    //             targ.value = 1;
    //             targ.previousElementSibling.disabled = true;
    //         }else{
    //             targ.previousElementSibling.disabled = false;
    //         }
    //     });

    // Секция проверки в случае наличия у нас на странице субменю
    const submenuElem = document.querySelector('.page_inner--layout-sidebar nav');

    if (submenuElem) {
        const smoothScrollTo = (elem) => {
            const rect = elem.getBoundingClientRect();

            window.scrollTo({
                top: rect.top + window.scrollY,
                behavior: 'smooth'
            });
        };

        const watcherOptions = {
            root: null,
            rootMargin: '-30% 0px',
            threshold: 0.01
        };

        let targets = [];

        submenuElem.querySelectorAll('a,button').forEach(trigger => {
            const targSelector = trigger.getAttribute('href') || trigger.getAttribute('data-target');
            const targElement = document.querySelector(targSelector);

            if (targElement) {
                trigger.addEventListener('click', (e) => {
                    smoothScrollTo(targElement);

                    e.preventDefault();
                });

                targElement.addEventListener(Watcher.IN_VIEWPORT, () => {
                    const currActiveElem = submenuElem.querySelector('.active');

                    if (currActiveElem && currActiveElem !== trigger) {
                        currActiveElem.classList.remove('active');
                    }

                    trigger.classList.add('active');
                });

                targets.push(targElement);
            };
        });

        Watcher.watchElements(targets, watcherOptions);
    }
});