/**
 * elem - Элемент самого датепикера
 * linkedElem - кнопка, вызывающая его, туда будет записываться строка с датой
 * currDate - текущая дата в виде объекта даты
 * disableFrom - залочить все дни начиная с этого. Формат - объект даты
 * disableBeforeCurrent - залочить все дни до текущего
 */
const oneDaySpace = 100 / 7;
const monthNames = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь'
];

export default class DatePicker {
    constructor(elem, linkedElem, currDate = new Date(), disableBeforeCurrent = true, disableFrom = null, identifier = 'datePick' + (new Date().getTime())) {
        this.elem = elem;
        this.linkedElem = linkedElem;
        this.originDate = new Date(currDate.getTime());
        this._currDate = currDate;
        this.disableFrom = disableFrom;
        this.identifier = identifier;
        this.disableBeforeCurrent = disableBeforeCurrent;
        this.selectedDay = currDate.getTime();

        this.elem.datePicker = this;

        this.monthControls = {
            prev: this.elem.querySelector('.form_default--datepicker-calendar-month > button:first-child'),
            next: this.elem.querySelector('.form_default--datepicker-calendar-month > button:last-child'),
            text: this.elem.querySelector('.form_default--datepicker-calendar-month > span')
        };

        this.selectContainer = this.elem.querySelector('.form_default--datepicker-calendar-select');
        this.elem.addEventListener('change', (ev) => {
            this._currDate.setDate(ev.target.value);
            this.selectedDay = this._currDate.getTime();
            this.linkedElem.innerText = this._currDate.toLocaleDateString();
        });

        this.monthControls.prev.onclick = () => this.monthPrev();
        this.monthControls.next.onclick = () => this.monthNext();

        this.init(true);

        this.linkedElem.innerText = this._currDate.toLocaleDateString();
    }

    init(selectCurrent = false) {
        let firstDayCurrMonth = new Date(this._currDate.getTime());
        firstDayCurrMonth.setDate(1);
        // Получим количество пробелов
        const spaceCount = DatePicker.getDayFromDate(firstDayCurrMonth);

        // Сгенерим сетку
        const mon = this._currDate.getMonth();
        const origTime = this.originDate.getTime();
        const disabledTime = this.disableFrom != null ? this.disableFrom.getTime() : -1;
        const prevDisabled = origTime > firstDayCurrMonth.getTime() - 1;

        let template = '';
        while (firstDayCurrMonth.getMonth() === mon) {
            const currDay = firstDayCurrMonth.getDate();
            const currTime = firstDayCurrMonth.getTime();
            const isDisabledBack = this.disableBeforeCurrent && origTime > currTime;
            const isDisabledForward = disabledTime > 0 && currTime >= disabledTime;

            template += `
                <div${currDay === 1 ? ` style="margin-left: ${oneDaySpace * spaceCount}%;"` : ''}>
                    <label>
                        <input type="radio" name="${this.identifier}" ${isDisabledBack || isDisabledForward ? 'disabled' : ''}
                            value="${currDay}"${selectCurrent && currTime === this.selectedDay ? ' checked' : ''} />
                        <span>${currDay}</span>
                    </label>
                </div>
            `;

            firstDayCurrMonth.setDate(currDay + 1);
        }

        // Залочим или разлочим кнопки управления месяцами
        this.monthControls.prev.disabled = prevDisabled;
        this.monthControls.next.disabled = disabledTime > 0 && firstDayCurrMonth.getTime() >= disabledTime;

        this.selectContainer.innerHTML = template;
        this.monthControls.text.innerText = `${monthNames[this._currDate.getMonth()]} ${this._currDate.getFullYear()}`;
    }

    monthPrev(){
        this._currDate.setMonth(this._currDate.getMonth() - 1);
        this.init(true);
    }

    monthNext(){
        this._currDate.setMonth(this._currDate.getMonth() + 1);
        this.init(true);
    }

    get currDate() {
        return this._currDate;
    }

    set currDate(dateObj) {
        this._currDate = dateObj;
        this.selectedDay = this._currDate.getTime();
        this.init(true);
    }

    static getDayFromDate(dateObj) {
        let day = dateObj.getDay();
        if (day == 0) day = 7; // сделать воскресенье (0) последним днем
        return day - 1;
    }
}