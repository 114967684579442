/**
 * Модуль плавающего меню (любого)
 */

const defaults = {
    offset: 100,  // Отступ от верха экрана до верха элемента, когда отменяется фиксация
    hide: true  // Скрывать ли элемент при прокрутке вниз и показывать при прокрутке вверх
};

export default class FloatMenu {
    constructor(elem, options = defaults) {
        this.elem = elem;
        this.opts = Object.assign({}, defaults, options);
        this.lastPos = 0;

        this.tick();

        window.addEventListener('scroll', (e) => {
            this.tick();
        });
    }

    tick() {
        let st = window.scrollY;
        let delta = this.lastPos - st;

        if(delta === 0) return;

        let offset = this.elem.clientHeight + this.options.offset;
        let isBreakpoint = this.lastPos <= offset;

        if (st > offset) {
            this.elem.classList.add('fix');

            if (this.options.hide && !isBreakpoint) {
                if (delta <= 0) {
                    this.hide();
                } else {
                    this.show();
                }
            }
        } else if (st == 0) {
            this.reset();
        }

        this.lastPos = st;
    }

    toggleExpand(){
        this.elem.classList.toggle('expanded');

        return this.elem.classList.contains('expanded');
    }

    show(){
        this.elem.classList.remove('hide');
        this.elem.classList.add('show');
    }

    hide(){
        this.elem.classList.remove('show');
        this.elem.classList.add('hide');
    }

    reset(){
        this.elem.classList.remove('fix', 'show', 'hide');
    }

    get options() {
        return this.opts;
    }

    get element() {
        return this.elem;
    }

    static get defaults() {
        return defaults;
    }
}