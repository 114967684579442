/**
 * Компонент валидации полей форм
 */

import validators from '../data/validators';

const errorLabelSelector = '.input_container--error';

export default class FieldValidation {
    constructor(elem, validator) {
        this.elem = elem;
        this.elem.fieldValidator = this;

        if (typeof validators[validator] !== 'undefined') {
            this.validator = validators[validator];
            this.elem.setAttribute('pattern', this.validator);
        }

        this.elem.addEventListener('focus', (e) => {
            this.elem.classList.add('validation-touched');

            this.elem.addEventListener('blur', (ee) => {
                this.validate();
            });

            if (this.elem.tagName === 'SELECT') {
                this.elem.addEventListener('change', (ee) => {
                    this.validate();
                });
            }
        }, { once: true });
    }

    validate() {
        let defaultError;
        let flag = false;

        this.elem.parentNode.querySelectorAll(errorLabelSelector).forEach(errfld => {
            if (!this.elem.validity.valid) {
                let validity = errfld.getAttribute('data-validity');

                if (validity) {
                    if (this.elem.validity[validity]) {
                        flag = true;
                        errfld.classList.add('validation-visible');
                        return;
                    }
                } else {
                    defaultError = errfld;
                }
            }

            errfld.classList.remove('validation-visible');
        });

        if (!flag && defaultError) {
            defaultError.classList.add('validation-visible');
        }
    }
}