const rootClass = "input_container";

export default class FileInput {
    constructor(elem) {
        this.elem = elem;
        this.cont = this.elem.closest('.' + rootClass);
        this.form = this.elem.closest('form');
        // this.label = cont.querySelector('.input-container__label');
        // Создаем файловый контейнер
        this.fcont = document.createElement('span');
        this.fcont.classList.add(`${rootClass}--files`);
        this.fcont.style.display = 'none';

        this.rbtn = document.createElement('button');
        this.rbtn.type = 'button';

        this.fcont.append(this.rbtn);
        this.cont.append(this.fcont);

        this.elem.addEventListener('change', (e) => {
            if (this.elem.files.length > 0) {
                this.fcont.style.display = '';
                this.clear();
                this.fill();
            } else {
                this.fcont.style.display = 'none';
            }
        });

        this.rbtn.addEventListener('click', (e) => {
            this.resetField();
        });

        this.form.addEventListener('reset', (e) => {
            this.clear();
            this.fcont.style.display = 'none';
        });
    }

    clear() {
        this.fcont.childNodes.forEach(node => {
            if (node.tagName === 'SPAN') {
                this.fcont.removeChild(node);
            }
        });
    }

    resetField() {
        this.elem.value = '';
        this.clear();
        this.fcont.style.display = 'none';
    }

    fill() {
        for (let i = 0; i < this.elem.files.length; i++) {
            const currFile = this.elem.files[i];
            const fileName = document.createElement('span');
            fileName.innerText = currFile.name;
            this.fcont.append(fileName);
        }
    }
}