/**
 * Маскировальщик полей ввода для форм
 */

import MASK_TYPES from '../data/maskers';

const NUMBER_PLACEHOLDER = '_';
const NUMBER_PLACEHOLDER_REGEX = /_/;

const ALT_CARET_REGEX = /\d?\D*$/;

export default class FieldMasker {
    constructor(elem, maskType) {
        if (typeof MASK_TYPES[maskType] === 'undefined') {
            console.warn(`Wrong mask type (${maskType})!`);
            return;
        }

        this.elem = elem;
        this.elem.fieldMasker = this;
        this.mask = MASK_TYPES[maskType];

        elem.addEventListener('focus', FieldMasker.focusInHandler);
        elem.addEventListener('blur', FieldMasker.focusOutHandler);
        elem.addEventListener('input', FieldMasker.mask);
    }

    get maskedValBeforeCaret() {
        const caretIndex = this.elem.selectionStart || this.elem.value.length;

        return this.maskVal(this.elem.value.slice(0, caretIndex));
    }

    get maskedVal() {
        return this.maskVal(this.elem.value);
    }

    maskVal(value) {
        let newVal = this.mask.pattern;
        let filteredVal = typeof this.mask.filter !== 'undefined' ? this.mask.filter(value) : FieldMasker.filterNum(value);
        let phReg = typeof this.mask.placeholder !== 'undefined' ? this.mask.placeholder : NUMBER_PLACEHOLDER_REGEX;

        for (let i = 0; i < filteredVal.length; i++) {
            newVal = newVal.replace(phReg, filteredVal[i]);
        }

        return newVal;
    }

    static get MASK_TYPES() {
        return MASK_TYPES;
    }

    static focusInHandler(e) {
        if (e.target.value === '') {
            e.target.value = e.target.fieldMasker.mask.pattern;
        }
    }

    static focusOutHandler(e) {
        if (e.target.value === e.target.fieldMasker.mask.pattern) {
            e.target.value = '';
        }
    }

    static fillPlaceholder(val, length, placeholder) {
        let strval = val.toString();

        if (strval.length < length) {
            return strval.padEnd(length, placeholder[0]);
        } else if (strval.length > length) {
            return strval.substring(0, length);
        }

        return val;
    }

    static mask(e) {
        const fm = e.target.fieldMasker;
        const newVal = fm.maskedVal;
        let newCaret;

        newCaret = FieldMasker.getCaretNumIndex(e.target, fm.maskedValBeforeCaret);

        e.target.value = newVal;

        e.target.selectionStart = newCaret[0];
        e.target.selectionEnd = newCaret[1];
    }

    static filterNum(val) {
        return val.replace(/\D/g, '');
    }

    static getCaretNumIndex(targ, newVal) {
        let prevCaret = targ.selectionStart || targ.value.length;

        let caretPos = newVal.indexOf(NUMBER_PLACEHOLDER);
        let altCaret = newVal.match(ALT_CARET_REGEX);
        let newCaret;

        if (caretPos > 0) {
            newCaret = [altCaret.index + 1, altCaret.index + 1];
        } else if (prevCaret < targ.value.length) {
            while (prevCaret < targ.value.length && !/\d/.test(targ.value[prevCaret])) {
                prevCaret++;
            }

            newCaret = [prevCaret, prevCaret + 1];
        } else if (prevCaret >= newVal.length) {
            newCaret = [newVal.length, newVal.length];
        } else {
            newCaret = [0, 0];
        }

        return newCaret;
    }
}