import TaskRunner from '../base/TaskRunner';
// import LiveEvent from './base/LiveEvent';

let hasApi = false;
let isLoaded = false;
let sheduledPlayers = [];
let currentPlaying;

const apiSource = "https://www.youtube.com/iframe_api";
const ytRegex = /.*(?:youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=)([^#\&\?]*).*/;

const parseVidId = (url) => {
    var match = url.match(ytRegex);
    return (match && match[1].length == 11) ? match[1] : false;
};

const isLoadedEvent = (e) => {
    if (sheduledPlayers.length > 0) {
        sheduledPlayers.forEach(val => {
            createPlayer(val.id, val.el);
        });

        sheduledPlayers = [];
    }

    isLoaded = true;
};

const onPlayerReady = (event) => {
    // 4. The API will call this function when the video player is ready.
    setTimeout(() => {
        const playerCont = (event.target.f || event.target.h).closest('.youtube-root');
        const isAutoplay = playerCont.hasAttribute('data-autoplay');

        if (isAutoplay) {
            event.target.mute();
            event.target.playVideo();
        }
    }, 100);
};

const onPlayerStateChange = (event) => {
    // 5. The API calls this function when the player's state changes.
    //    The function indicates that when playing a video (state=1),
    //    the player should play for six seconds and then stop.
    const playerCont = (event.target.f || event.target.h).closest('.youtube-root');

    switch (event.data) {
        case YT.PlayerState.PLAYING:
            playerCont.classList.add('youtube-playing');
            playerCont.classList.remove('youtube-paused');

            if (currentPlaying != undefined && currentPlaying != null) {
                currentPlaying.stopVideo();
            }

            currentPlaying = event.target;
            break;
        case YT.PlayerState.PAUSED:
            playerCont.classList.add('youtube-paused');
            playerCont.classList.remove('youtube-playing');

            if (currentPlaying == event.target) {
                currentPlaying = null;
            }
            break;
    }
};

const createPlayer = (vidId, elem) => {
    // 3. This function creates an <iframe> (and YouTube player)
    //    after the API code downloads.
    const autoplay = elem.hasAttribute('data-autoplay');
    const player = document.createElement('div');
    player.classList.add('youtube-player');

    const playerContainer = document.createElement('div');
    playerContainer.classList.add('youtube-container');

    playerContainer.append(player);
    elem.prepend(playerContainer);

    const playpause = document.createElement('div');
    playpause.classList.add('youtube-playpause');

    elem.append(playpause);

    elem.style.backgroundImage = `url(https://img.youtube.com/vi/${vidId}/hqdefault.jpg)`;

    let yt = new YT.Player(player, {
        // height: '360',
        // width: '640',
        videoId: vidId,
        events: {
            'onReady': onPlayerReady,
            'onStateChange': onPlayerStateChange
        },
        playerVars: {
            'autoplay': Number(autoplay),
            'autohide': 1,
            'showinfo': 0,
            'loop': Number(autoplay),
            'modestbranding': 1,
            'controls': 0,
            'rel': 0,
            'fs': 0,
        }
    });
};

const initPlayer = (cont) => {
    const initialized = cont.classList.contains('youtube-initialized');

    if (initialized) return;

    const link = cont.getAttribute('data-youtube');
    const vidId = parseVidId(link);

    if (!hasApi) {
        // 2. This code loads the IFrame Player API code asynchronously.
        var tag = document.createElement('script');
        //tag.onload = isLoadedEvent;
        window.onYouTubeIframeAPIReady = isLoadedEvent;

        tag.src = apiSource;
        var firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        hasApi = true;
    }

    if (isLoaded) {
        createPlayer(vidId, cont);
    } else {
        sheduledPlayers.push({ id: vidId, el: cont });
    }

    cont.classList.add('youtube-initialized', 'youtube-root');
};

// const onClick = (e, target) => {
//     let link = target.getAttribute('data-youtube');
//     let vidId = parseVidId(link);

//     if (!hasApi) {
//         // 2. This code loads the IFrame Player API code asynchronously.
//         var tag = document.createElement('script');
//         //tag.onload = isLoadedEvent;
//         window.onYouTubeIframeAPIReady = isLoadedEvent;

//         tag.src = apiSource;
//         var firstScriptTag = document.getElementsByTagName('script')[0];
//         firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

//         hasApi = true;
//     }

//     if (isLoaded) {
//         createPlayer(vidId, target);
//     } else {
//         sheduledPlayers.push({ id: vidId, el: $me });
//     }

//     target.classList.add('youtube-initialized', 'youtube-root');

//     e.preventDefault();
// };

TaskRunner.add(() => {
    // LiveEvent.on('click', '[data-youtube]:not(.youtube-initialized)', onClick);

    document.querySelectorAll('[data-youtube]').forEach(cont => {
        initPlayer(cont);
    });
});