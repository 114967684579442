/**
 * Класс - форматирует поле в заданном виде. Список форматтеров как обычно
 */

import FORMATTERS from '../data/formatters';

export default class FieldFormatter{
    constructor(elem, formatter){
        this.elem = elem;
        this.formatter = FORMATTERS[formatter];

        if(!this.formatter){
            console.warn('This type of formatter does not exist!');
            return;
        }

        this.elem.addEventListener('blur', (e)=>{
            e.target.value = this.formatter(e.target.value);
        });
    }
}