import TaskRunner from '../base/TaskRunner';
import FileInput from '../components/FileInput';
import FieldMasker from '../components/FieldMasker';
import FieldValidation from '../components/FieldValidation';
import LiveEvent from '../base/LiveEvent';
import DatePicker from '../components/DatePicker';
import FieldFormatter from '../components/FieldFormatter';

TaskRunner.add(() => {
    const initFld = function(inp){
        switch (inp.type) {
            case 'file':
                new FileInput(inp);
                break;
        }

        const validator = inp.getAttribute('data-validation');

        if (validator != null) {
            new FieldValidation(inp, validator);
        }

        const masker = inp.getAttribute('data-mask');

        if(masker != null && masker.length > 0){
            new FieldMasker(inp, masker);
        }

        const formatter = inp.getAttribute('data-format');

        if(formatter != null && formatter.length > 0){
            new FieldFormatter(inp, formatter);
        }
    };

    window.reInitFormField = initFld;

    document.querySelectorAll('input, textarea, select').forEach(initFld);

    document.querySelectorAll('[data-validation-unlock]').forEach(form => {
        let unlockTarg = document.getElementById(form.getAttribute('data-validation-unlock'));

        form.addEventListener('input', (e) => {
            let allFields = form.querySelectorAll('input,textarea,select');
            let allValid = true;

            for (let inp of allFields) {
                if (!inp.checkValidity()) {
                    allValid = false;
                    break;
                }
            }

            unlockTarg.disabled = !allValid;
        });
    });

    LiveEvent.on('click', '.input_container--switch', (e, targ) => {
        const input = targ.parentElement.querySelector('input');

        if(input){
            input.type = input.type === 'password' ? 'text' : 'password';
        }
    }).on('click', '.input_container--expandable-insert', (e, targ) => {
        const input = targ.closest('.input_container--expandable').querySelector('input,select,textarea');

        if(input){
            input.value = targ.innerText;
        }
    });

    window.DatePicker = DatePicker;
});