import animItems from '../data/animItems';
import TaskRunner from '../base/TaskRunner';
import * as Watcher from '../components/viewportWatcher';

const defaults = {
    anim: 'animate__fadeIn',
    delayBase: 0,
    delayStep: 0,
    itemClass: 'anim-item',
    parentClass: 'anim-parent',
    classWhenViewport: 'animate__animated'
};

let observer;

const onViewportHandler = (e) => {
    if (e.target.watchOnce) {
        observer.unobserve(e.target);
    }

    e.target.classList.add('anim-inViewport', `anim-order${e.detail}`);
    e.target.querySelectorAll('.' + defaults.itemClass).forEach(item => {
        item.classList.add(defaults.classWhenViewport);
    });
};

TaskRunner.add(() => {
    let elements = [];

    animItems.forEach(itm => {
        let list = (itm.customOptions) ? [] : elements;

        document.querySelectorAll(itm.selector).forEach(targ => {
            let watchingTarget = targ;

            if (itm.targets != undefined && itm.targets.length > 0) {
                targ.classList.add(defaults.parentClass);

                itm.targets.forEach(subtarg => {
                    let subtargSelector;
                    let anim;
                    let delayBase;
                    let delayStep;

                    switch (typeof subtarg) {
                        case "object":
                            subtargSelector = subtarg.selector;
                            anim = subtarg.anim || defaults.anim;
                            delayBase = subtarg.delayBase || defaults.delayBase;
                            delayStep = subtarg.delayStep || defaults.delayStep;
                            break;
                        case "string":
                            subtargSelector = subtarg;
                            anim = defaults.anim;
                            delayBase = defaults.delayBase;
                            delayStep = defaults.delayStep;
                            break;
                        default: return;
                    }

                    targ.querySelectorAll(subtargSelector).forEach(subtargElement => {
                        subtargElement.classList.add(defaults.itemClass, anim);
                        subtargElement.style['animation-delay'] = `${delayBase}s`;
                        delayBase += delayStep;
                    });
                });
            }

            // if(itm.createTrigger){

            // }

            watchingTarget.watchOnce = itm.once;
            watchingTarget.addEventListener(Watcher.IN_VIEWPORT, onViewportHandler, { once: itm.once });
            list.push(watchingTarget);
        });

        if(itm.customOptions && list.length > 0){
            Watcher.watchElements(list, itm.customOptions);
        }
    });

    if (elements.length > 0)
        observer = Watcher.watchElements(elements);
});