export default {
    ruPhone: {
        filter: (val) => {
            let numonly = val.replace(/\D/g, '') || '7';

            if (/7|8/.test(numonly[0])) numonly = numonly.slice(1, numonly.length);

            return numonly;
        },
        pattern: '+7 (___) ___-__-__'
    },
    cardNumber: {
        pattern: '____ ____ ____ ____'
    },
    passportNumber: {
        pattern: '____ ______'
    },
    passportSubdivisionCode: {
        pattern: '___-___'
    },
    snils: {
        pattern: '___-___-___ __'
    },
    cardExpiration: {
        pattern: '__/__'
    },
    smsCode: {
        pattern: '_ _ _ _'
    }
};