import TaskRunner from '../base/TaskRunner';
import sliders from '../data/swiper';
import { zeroPad } from '../base/util';

const fractionNumFunc = (num) => {
    return zeroPad(num, 2);
};

const defaults = {
    pagination: {
        el: '.swiper-pagination',
        clickable: true
    },
    paginationFraction: {
        el: '.swiper-pagination',
        type: 'fraction',
        formatFractionCurrent: fractionNumFunc,
        formatFractionTotal: fractionNumFunc
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    },
    scrollbar: {
        el: '.swiper-scrollbar',
        draggable: true
    }
};

const getPreloaderTemplate = () => {
    let preloader = document.createElement('div');
    preloader.classList.add('swiper-lazy-preloader', 'swiper-lazy-preloader-white');
    return preloader;
};


/**
 * Обёртка для слайдеров.
 * @param {DOM Object} elem Объект, который нужно обернуть и проинициализировать.
 */
export const wrapSlider = (elem, settings = undefined) => {
    let sliderIdentifier = elem.getAttribute('data-swiper');
    settings = settings || sliders[sliderIdentifier];

    if (typeof settings === 'object') {
        settings.el = undefined;
        let settObj = Object.assign({}, settings);

        elem.classList.add('swiper-root');

        let swiperCont = document.createElement('div');
        swiperCont.classList.add('swiper-container');
        let swiperWrap = document.createElement('div');
        swiperWrap.classList.add('swiper-wrapper');

        while (elem.children.length > 0) {
            let slide = elem.children[0];
            slide.classList.add('swiper-slide');
            swiperWrap.append(slide);
        }

        swiperCont.append(swiperWrap);
        elem.append(swiperCont);

        switch (settObj.pagination) {
            case 'default':
                // Отрисуем пагинацию, а шо делать
                settObj.pagination = Object.assign({}, defaults.pagination);

                let pagination = document.createElement('div');
                pagination.classList.add(settObj.pagination.el.replace(/\./g, ''));

                elem.append(pagination);

                settObj.pagination.el = pagination;
                break;
            case 'fraction':
                // Отрисуем пагинацию, а шо делать
                settObj.pagination = Object.assign({}, defaults.paginationFraction);

                let paginationFraction = document.createElement('div');
                paginationFraction.classList.add(settObj.pagination.el.replace(/\./g, ''));

                elem.append(paginationFraction);

                settObj.pagination.el = paginationFraction;
                break;
            case 'customProgress':
                const customProgressObject = document.createElement('div');
                customProgressObject.classList.add('swiper-progress_custom');

                const textH = elem.getAttribute('data-progress-h');
                const textV = elem.getAttribute('data-progress-v');

                const textHEl = document.createElement('span');
                textHEl.innerText = textH;
                const textVEl = document.createElement('span');
                textVEl.innerText = textV;

                customProgressObject.append(textHEl, textVEl);

                elem.append(customProgressObject);

                const calcCustomProgress = (swp) => {
                    const widthHeight = `${swp.progress * 100}%`;
                    customProgressObject.style.width = customProgressObject.style.height = widthHeight;
                };

                settObj.pagination = {};
                settObj.on = Object.assign({}, settObj.on, {
                    activeIndexChange: calcCustomProgress,
                    afterInit: calcCustomProgress
                });

                break;
        }

        if (settObj.navigation === 'default') {
            // Отрисуем навигацию, а шо делать
            settObj.navigation = Object.assign({}, defaults.navigation);

            let navigationPrev = document.createElement('button');
            navigationPrev.setAttribute('type', 'button');
            navigationPrev.classList.add(settObj.navigation.prevEl.replace(/\./g, ''));

            let navigationNext = document.createElement('button');
            navigationNext.setAttribute('type', 'button');
            navigationNext.classList.add(settObj.navigation.nextEl.replace(/\./g, ''));

            elem.append(navigationPrev, navigationNext);

            settObj.navigation.prevEl = navigationPrev;
            settObj.navigation.nextEl = navigationNext;
        }

        if (settObj.scrollbar === 'default') {
            // Отрисуем скроллбар, а шо делать
            settObj.scrollbar = Object.assign({}, defaults.scrollbar);

            let scrollbarEl = document.createElement('div');
            scrollbarEl.classList.add('swiper-scrollbar');

            elem.append(scrollbarEl);

            settObj.scrollbar.el = scrollbarEl;
        }

        if (settObj.lazy) {
            // Если у нас врублен лезилодинг
            swiperWrap.querySelectorAll('[data-src],[data-background],[data-srcset]').forEach(lazyElem => {
                lazyElem.classList.add('swiper-lazy');
                lazyElem.after(getPreloaderTemplate());
            });
        }

        if (settObj.thumbs != undefined) {
            // Инициализируем слайдер с тумбами
            const thumbSettings = Object.assign({}, settObj.thumbs.swiper);
            
            try{
                const thumbElement = elem.parentElement.querySelector(thumbSettings.el);
                const thumbSwiper = wrapSlider(thumbElement, thumbSettings);

                if(thumbSwiper){
                    settObj.thumbs = {
                        swiper: thumbSwiper
                    };
                }else{
                    settObj.thumbs = undefined;
                }
            }catch(er){
                console.error('Unable to find thumb object :(\ninitializing slider without it!');
                settObj.thumbs = undefined;
            }
        }

        let autoplay = Number(elem.getAttribute('data-autoplay'));
        if (autoplay > 0) {
            settObj.autoplay = {
                delay: autoplay,
                disableOnInteraction: false
            };
        }

        // Пришло время инициализировать слайдер
        let slider;

        if (settObj.mediaQuery && settObj.mediaQuery.length > 0) {
            const breakpoint = window.matchMedia(settObj.mediaQuery);
            const breakpointChecker = () => {
                if (breakpoint.matches === true) {
                    slider = new Swiper(swiperCont, settObj);
                    slider.origSettings = settObj;
                } else if (breakpoint.matches === false && slider !== undefined) {
                    slider.destroy(true, true);
                }
            };

            breakpoint.addEventListener('change', breakpointChecker);

            breakpointChecker();
        } else {
            slider = new Swiper(swiperCont, settObj);
            slider.origSettings = settObj;
        }

        if (settObj.linkWith) {
            // Связываем слайдеры
            slider.on('slideChange', function () {
                try {
                    let linkedSlider = document.querySelector(`${slider.params.linkWith} .swiper-container`).swiper;

                    if (linkedSlider != undefined) {
                        linkedSlider.slideTo(slider.realIndex);
                    }
                } catch (e) {
                    console.warn(e.message);
                }
            });
        }

        return slider;
    } else {
        console.warn('slider with id ' + sliderIdentifier + ' is not found');
    }

    return null;
};

export const initSliders = () => {
    let foundSliders = document.querySelectorAll('[data-swiper]:not(.swiper-root)');

    foundSliders.forEach(slider => {
        wrapSlider(slider);
    });
};

TaskRunner.add(() => {
    if (typeof Swiper === 'undefined') return;

    window.wrapSwiperSlider = wrapSlider;

    initSliders();
});