/**
 * Данный класс является альтернативой $(document).on('event', 'selector', callback);
 */

/**
 * Структура этого объекта такая: callbacks: {
 *  click: {
 *  selectors: [],
 * callbacks: [],
 * strselectors: ''
 * }
 * }
 */
let callbacks = {};

export default class LiveEvent {
    static on(event, selector, callback) {
        event = String(event);
        selector = String(selector);

        if (typeof callback !== 'function') {
            console.log('Third arfument must be the function!');
            return;
        }

        if (typeof callbacks[event] === 'undefined') {
            callbacks[event] = {
                selectors: [],
                callbacks: [],
                strselectors: ''
            };

            document.addEventListener(event, LiveEvent.mainHandler);
        }

        callbacks[event].selectors.push(selector);
        callbacks[event].callbacks.push(callback);
        callbacks[event].strselectors += (callbacks[event].strselectors.length > 0) ? ',' + selector : selector;

        return LiveEvent;
    }

    static off(event, selector) {
        if (typeof callbacks[event] !== 'undefined') {
            let selInd = callbacks[event].selectors.indexOf(selector);

            if(selInd >= 0){
                callbacks[event].selectors.splice(selInd, 1);
                callbacks[event].callbacks.splice(selInd, 1);
                callbacks[event].strselectors = callbacks[event].selectors.join(',');
            }

            if(callbacks[event].selectors.length === 0){
                delete callbacks[event];
                document.removeEventListener(event, LiveEvent.mainHandler);
            }
        }

        return LiveEvent;
    }

    static mainHandler(e) {
        if (typeof callbacks[e.type] !== 'undefined') {
            let targ = e.target.closest(callbacks[e.type].strselectors);

            if(targ !== null){
                for(let ind in callbacks[e.type].selectors){
                    if(targ.matches(callbacks[e.type].selectors[ind])){
                        callbacks[e.type].callbacks[ind].call(targ, e, targ);
                    }
                }
            }
        } else {
            document.removeEventListener(e.type, LiveEvent.mainHandler);
        }
    }
}